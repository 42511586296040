import {
  OnboardingContext,
  OnboardingView,
} from '../features/onboarding/OnboardingView.tsx'
import { useLazyRef, useSubscribe } from '../shared/lib/hooks.ts'
import { OnboardingStore } from '../features/onboarding/OnboardingStore.ts'
import { useAppModule } from '../features/appContext.ts'
import { useMount } from '../shared/lib/utils.ts'
import { useSnapshot } from 'valtio/react'
import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { urls } from '../shared/urls.ts'
import { messages } from '../shared/translations/messages.ts'
import { IntlProvider } from 'react-intl'
import { useOnboardingData } from '../features/onboarding/onboardingData.ts'
import { OnboardingStep } from '../shared/data/onboardingSteps.ts'

export function OnboardingPage() {
  return (
    <IntlProvider locale="ru" messages={messages.ru}>
      <OnboardingProvider onboarding={true}>
        <OnboardingView />
      </OnboardingProvider>
    </IntlProvider>
  )
}

export function OnboardingProvider(props: {
  step?: OnboardingStep
  onboarding?: boolean
  children: React.ReactNode
}) {
  const appModule = useAppModule()
  const authStore = useAppModule().authStore

  useMount(() => {
    appModule.authStore.trackMetrika('demo_lesson_start')
    appModule.authStore.trackEvent('edman_intro_start')
    void authStore.saveMarkData(true)
  })
  const data = useOnboardingData()

  const params = useParams<OnboardingPageParams>()
  const initialStep = params.step ?? 'hello'

  const store = useLazyRef(
    () =>
      new OnboardingStore(
        appModule.chatsApi,
        appModule.authStore,
        appModule.subscriptionService(),
        data,
        appModule.appSessionStorage,
        initialStep,
      ),
  ).current
  useMount(() => {
    void store.load()
  })
  const state = useSnapshot(store.state)
  const step = state.stepType
  const navigate = useNavigate()
  const routerPrevStepRef = useRef<OnboardingStep>()

  useSubscribe(store.state, () => {
    store.updateSession()
  })

  useLayoutEffect(() => {
    store.updateSession()
    routerPrevStepRef.current = initialStep
  }, [initialStep, store])

  useEffect(() => {
    if (
      props.onboarding &&
      !state.completed &&
      !state.goToDemo &&
      step !== routerPrevStepRef.current
    ) {
      navigate(urls.onboarding(step))
    }
  }, [navigate, step, state.completed, props.onboarding, state.goToDemo])

  // useMount(() => {
  //   if (!store.state.completed && !state.goToDemo) {
  //     console.log(555, step)
  //     navigate(urls.onboarding(step), { replace: true })
  //   }
  // })

  return (
    <OnboardingContext.Provider value={store}>
      {props.children}
    </OnboardingContext.Provider>
  )
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type OnboardingPageParams = {
  step: OnboardingStep
}

import {
  ProgramContext,
  ProgramView,
} from '../features/programs/programView.tsx'
import { useLazyRef } from '../shared/lib/hooks.ts'
import { useAppModule } from '../features/appContext.ts'
import { useMount } from '../shared/lib/utils.ts'
import { AppHeader } from '../features/appHeader/appHeader.tsx'
import { Navigate, useNavigate } from 'react-router-dom'
import { urls } from '../shared/urls.ts'
import { useEffect } from 'react'
import { useSnapshot } from 'valtio/react'
import { useOnboardingStore } from '../features/onboarding/OnboardingView.tsx'

export function ProgramPage(props: { registration: boolean }) {
  const appModule = useAppModule()
  const onboardingStore = useOnboardingStore()
  const { completed } = useSnapshot(onboardingStore.state)

  const store = useLazyRef(() =>
    appModule.programStore(props.registration),
  ).current
  useMount(() => {
    void store.loadPrograms()
  })

  const state = useSnapshot(store.state)
  const navigate = useNavigate()

  useEffect(() => {
    // if (!completed) {
    //   navigate(urls.onboarding(), { replace: true })
    //   return
    // }

    if (state.urlParams) {
      navigate(urls.learningProfile(state.urlParams), { replace: true })
    }
  }, [navigate, state.urlParams, completed])

  if (props.registration && appModule.authStore.isLogin()) {
    return <Navigate to={urls.index} />
  }
  return (
    <ProgramContext.Provider value={store}>
      <div className="flex w-full flex-col">
        <AppHeader />
        <ProgramView />
      </div>
    </ProgramContext.Provider>
  )
}
